<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4">
        <v-card>
          <v-toolbar :color="actionColor" dark>
            <v-toolbar-title>{{ $helper.tRoute($route.name) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="$router.replace({ name: 'home' })">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text>
            <v-form id="form" ref="form">
              <v-text-field
                v-model="form.name"
                id="name"
                :label="$t('Cols.users.name')"
                maxlength="25"
                ref="name"
                :rules="[rules.required]"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.email"
                id="email"
                :label="$t('Cols.users.email')"
                maxlength="35"
                ref="email"
                :rules="[rules.required, rules.email]"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.password"
                @click:append="showPassword = !showPassword"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :hint="passwordHint"
                id="password"
                maxlength="20"
                :label="$t('Cols.users.password')"
                ref="password"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="form.password_confirmation"
                @click:append="showPassword = !showPassword"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :hint="passwordHint"
                id="password_confirmation"
                maxlength="20"
                :label="$t('Cols.users.password_confirmation')"
                ref="password_confirmation"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                validate-on-blur
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="register" block :color="actionColor" dark id="register"
              >{{ $t("Auth.register") }}
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn
              @click="$router.replace({ name: 'login' })"
              block
              :color="actionColor"
              dark
              id="alreadyRegistered"
              text
              >{{ $t("Auth.alreadyRegistered") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Register",

  data() {
    return {
      actionColor: this.$settings.appColor,

      form: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
      },

      loading: false,

      rules: {
        email: (v) => /.+@.+\..+/.test(v) || this.$t("Auth.validEmail"),
        min: (v) =>
          (v && v.length >= this.passwordLength) ||
          this.$t("Auth.minChars", [this.passwordLength]),
        required: (v) => !!v || this.$t("fieldRequired"),
      },

      showPassword: false,
    };
  },

  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    passwordLength() {
      return process.env.VUE_APP_APP_ENV == "local" ? 1 : 8;
    },

    passwordHint() {
      return this.$t("Auth.passwordHint", [this.passwordLength]);
    },
  },

  watch: {
    authUser(val) {
      if (val) {
        this.alertSuccess(this.$t("Auth.welcome", [this.authUser.name]));
        this.$router.replace({ name: "home" });
      }
    },
  },

  created() {
    if (this.$route.query.email) this.form.email = this.$route.query.email;
  },

  methods: {
    ...mapActions({
      authRegister: "auth/register",
      alertError: "app/alertError",
      alertSuccess: "app/alertSuccess",
      alertWarning: "app/alertWarning",
    }),

    error(e) {
      if (this.$helper.error(e) == "The given data was invalid.") {
        this.$helper.setFieldErrors(e, this);
        this.alertError(this.$t("invalidData"));
      } else {
        let message = this.$t("Auth.loginError") + this.$helper.error(e);
        this.alertError(message);
      }
    },

    register() {
      if (this.validForm()) {
        this.loading = true;
        this.authRegister(this.form)
          .catch((e) => this.error(e))
          .finally(() => (this.loading = false));
      }
    },

    validForm() {
      if (this.$refs.form.validate()) return true;

      this.alertWarning(this.$t("invalidData"));
    },
  },
};
</script>
